import { configureStore } from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'
import user from './user'
import auth from './auth'
import extraction from './extraction'
import { authApi } from 'services/auth'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { api } from 'services/api'
import { authzApi } from 'services/auth/authz'

const PERSISTED_KEYS: string[] = ['user', 'auth']

const store = configureStore({
  reducer: {
    user,
    auth,
    extraction,
    [authApi.reducerPath]: authApi.reducer,
    [api.reducerPath]: api.reducer,
    [authzApi.reducerPath]: authzApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
    })
      .concat(authApi.middleware)
      .concat(authzApi.middleware)
      .concat(api.middleware)
      .concat(save({ states: PERSISTED_KEYS })),
  preloadedState: load({ states: PERSISTED_KEYS }),
})

setupListeners(store.dispatch)

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
